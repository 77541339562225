import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import BlogContent from "../components/blog-content";
import Comments from "../components/comments";
import CommentsForm from "../components/comments-form";
import Thumbnail from "../components/thumbnail";
import Icon from "../components/icon.tsx";
import AdvDis from "../components/adv-dis";
import Out, { OutProductRedirect } from "../components/out-product";
import SimilarProducts from "../components/similar-products";
import ProductsToc from "../components/products-toc";
import Breadcrumbs from "../components/breadcrumbs";
import useDescription from "../hooks/useDescription";
import ProductCheckboxComparison from "../components/product-comparison-checkbox";
import Translate from "../components/translate";
import scrollTo from "gatsby-plugin-smoothscroll";
import { SCROLL_CLASS, DIRECTION_DOWN_CLASS, DIRECTION_UP_CLASS, } from "../utils/classes";
import RequirementsList from "../components/requirements-list";
import Layout from "../components/layout";
import ProductInputs from "../components/product-inputs";
import Faq from "../components/faq";

import BaseHead from '../components/base-head';
import siteMetdata from "../utils/site-metadata";

export const Head = ({ ...props }) => (
  <BaseHead {...props}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetdata.domain}/social-media/firm/${props.pageContext.mysqlId}.png`} />}
  </BaseHead>
);

const DFirm = ({
  data: { mysqlFirm, faq, allMysqlFirm, allMysqlDescriptionFirm, allMysqlComments },
  pageContext: { product, mysqlId, breadcrumbs },
  location: { pathname },
}) => {
  const {
    name,
    mysqlImage,
    address_city,
    address_code,
    address_no,
    age_min,
    amount_first,
    amount_min,
    amount_max,
    period_min,
    period_max,
    type,
    type_main,
    url,
    age_max,
    requirements,
    address_street,
    workhours,
    rating,
    phone,
    email,
    domain,
    address_name,
    disadvantages,
    advantages,
  } = mysqlFirm;

  const d = useDescription(allMysqlDescriptionFirm.nodes);
  const [isOpenToc, setIsOpenToc] = useState(false);

  const [productSettings, setProductsSettings] = useState({
    subtypes: {
      firm: `${type_main}`,
    },
    input: { 
      amount_min,
      amount: Math.max(amount_first, 1200), 
      amount_max,
      time_min: period_min,
      time: Math.min(period_min + 7, period_max),
      time_max: period_max,
    }
  });

  const getProductType = () => {
    if (!product["types"]) return "";
    return product.types.slice(-1) !== "s"
      ? `${product.types}s${product.subtypes[product.types]}`
      : `${product.types}${product.subtypes[product.types]}`;
  };
  const product_type = getProductType();
  const config_cta_button = `${product_type}_cta_button`;

  let idTimeout = null;
  const scroll = () => {
    clearTimeout(idTimeout);

    document.body.classList.remove(DIRECTION_UP_CLASS);
    document.body.classList.add(SCROLL_CLASS, DIRECTION_DOWN_CLASS);
    scrollTo('#addComment');

    idTimeout = setTimeout(
      () => document.body.classList.remove(SCROLL_CLASS),
      2000
    );
  };

  const [isOpenForm, setIsOpenForm] = useState(false);
  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const [isFaq, setIsFaq] = useState(false);
  const toggleFaq = (value) => setIsFaq(value ?? !isFaq)

  const fetchCost = () => {
    const timeType = type_main === 1 ? 'day' : 'month';
    const cost_url_api = `https://cms.akredo.pl/api/json/costs?slug[]=${url}&amount=${productSettings.input.amount}&period=${productSettings.input.time}&time_type=${timeType}`;
    
    fetch(cost_url_api)
      .then((res) => res.json())
      .then((result) => {
        if (result.costs?.[url]) {
          setProductsSettings({
            ...productSettings,
            costs: result.costs[url],
          });
        }
      })
  };

  useEffect(() => {
    fetchCost();
  }, [productSettings.input.time, productSettings.input.amount, type_main]);

  return (
    <Layout pathname={pathname}>
      <div className={["card-header", 'intro-products2', b.dFlex, b.justifyContentCenter].join(" ")}>
      </div>
      <div className={[b.container, 'breadcrumbs-mobile-2', b.py3, b.ptLg5, "scroll-inner"].join(' ')}>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <article className={"firm-page"}>
        <div className={["local-firm-page", "blog-content", "post-content"].join(" ")}>
          <div className={b.container}>
            <div className={b.row}>
              <div className={b.colLg8}>
                <div className={[b.dFlex, b.mt3, b.alignItemsCenter].join(" ")}>
                  <div className={["icon-box-acc"].join(" ")}>
                    {type_main === 1 && <Icon size={40} icon="Loans" />}
                    {type_main === 2 && <Icon size={40} icon="Installment-loan" />}
                  </div>
                  <h1 className={["big-title", b.pl3, b.mb0].join(" ")}>{name} - <Translate tKey="opinions and payday calculator" /></h1>
                </div>
              </div>
              <div className={[b.colLg4,b.mbLg5,b.pbLg5, b.orderLgLast].join(' ')}>
                {mysqlImage && (
                  <>
                    <div className={["bank-box", "padding-bottom", "sticky-box",].join(" ")}>
                      <div className={"bank-padding-box"}>
                        <div className='bank-padding-img cursor-pointer' onClick={OutProductRedirect(mysqlFirm)}>
                          <Thumbnail image={mysqlImage} alt={name} />
                        </div>
                        <div className={[b.dFlex, b.pb2, b.justifyContentCenter, b.mt3,].join(" ")} onClick={() => scroll('addComment')} role="button" tabIndex={0}>
                          {!!rating && mysqlFirm.rating !== "1" ? (
                            <div className="stars-container">
                              <div className="star-box" style={{ width: ` ${rating}% ` }}>
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" />
                              </div>
                              <div className='empty-star-box'>
                                <Icon size={20} icon="Empty-star" className={b.mr1} />
                                <Icon size={20} icon="Empty-star" className={b.mr1} />
                                <Icon size={20} icon="Empty-star" className={b.mr1} />
                                <Icon size={20} icon="Empty-star" className={b.mr1} />
                                <Icon size={20} icon="Empty-star" />
                              </div>
                            </div>
                          ) : (
                            <div className="stars-container">
                              <div>
                                <Icon size={20} icon="Empty-star" className={b.mr1} />
                                <Icon size={20} icon="Empty-star" className={b.mr1} />
                                <Icon size={20} icon="Empty-star" className={b.mr1} />
                                <Icon size={20} icon="Empty-star" className={b.mr1} />
                                <Icon size={20} icon="Empty-star" />
                              </div>
                            </div>
                          )}
                        </div>
                        {!!rating && mysqlFirm.rating !== "1" ? (
                          <span className={[b.dFlex, b.justifyContentCenter, "toc-title", "blackToWhite", 'font-size-20'].join(" ")}>
                            {(rating / 20).toFixed(1)}
                          </span>
                        ) : (
                          <span className={[b.dFlex, b.justifyContentCenter, "toc-title", "blackToWhite", 'font-size-20'].join(" ")}>
                            0.0
                          </span>
                        )}
                      </div>
                      <button className={[b.btn, "btn-light", b.mt2].join(" ")} onClick={() => { toggleForm();toggleFaq(false); }}>
                        <Icon size={20} icon="Plus-sign" className={[b.mr1, "icon-blog"].join(" ")} />
                        <Translate tKey="Add opinion" />
                      </button>
                      <Out className={[b.btn, "btn-success", "btn-cta", b.mt2, b.fontWeightNormal, b.textWhite].join(" ")}
                        prefixConfig={config_cta_button} item={mysqlFirm} defaultIcon="Taking-money" defaultDescription="Apply"/>
                      <div className={[b.dFlex, b.justifyContentCenter, "blueToWhite",].join(" ")}>
                        <div className={b.w100}>
                          <button className={["btn-hidden", "blueToWhite", b.mt1, "margin-1", b.w100, b.alignItemsCenter, 'iconToWhite'].join(" ")} onClick={() => setIsOpenToc(!isOpenToc)}>
                            {!isOpenToc ? (
                              <Icon size={25} icon="Angle-down" className={[b.mr1, "icon-blog"].join(" ")} />
                            ) : ("")}
                            {!isOpenToc ? <Translate tKey="Toc" /> : ""}
                          </button>
                          {isOpenToc ? (
                            <ProductsToc selector=".firm-page .blog-content h2[id]" className='blackToWhite' />
                          ) : ("")}

                          <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                            <button className={[b.mt2, "btn-hidden", "blueToWhite", 'iconToWhite'].join(" ")} onClick={() => setIsOpenToc(!isOpenToc)}>
                              {!isOpenToc ? ("") : (
                                <Icon size={25} icon="Angle-up" className={[b.mr1, "icon-blog"].join(" ")} />
                              )}
                              {!isOpenToc ? "" : <Translate tKey="Collapse the toc" />}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="local-firm-box-1">
                  <span className='font-size-24'><Translate tKey="Info about" /> {name}</span>
                  <div className={b.row}>
                    <div className={[b.col12, b.dFlex, b.my1].join(" ")}>
                      <div className={b.w25}>
                        <small className='blueToGray'><Translate tKey="Firm name" /></small>
                      </div>
                      <span className={['firm-box-text', 'firm-box-text-color'].join(' ')}>{address_name || '-'}</span>
                    </div>
                    <div className={[b.col12, b.dFlex, b.my1].join(" ")}>
                      <div className={b.w25}>
                        <small className='blueToGray'><Translate tKey="Address" /></small>
                      </div>
                      <div className={['firm-box-text', 'firm-box-text-color'].join(' ')}>
                        <div className={b.justifyContentStart}>
                          {address_street && address_no && address_code && address_city ? (<>
                            <div>
                              {address_street} {address_no},
                            </div>
                            <div>
                              {address_code} {address_city}
                            </div>
                          </>) : '-'}
                        </div>
                      </div>
                    </div>
                    <div className={[b.col12, b.dFlex, b.my1].join(" ")}>
                      <div className={b.w25}>
                        <small className={['blueToGray', b.textCapitalize].join(' ')}><Translate tKey="phone" /></small>
                      </div>
                      <span className={['firm-box-text', 'firm-box-text-color'].join(' ')}>{phone || '-'}</span>
                    </div>
                    <div className={[b.col12, b.dFlex, b.my1].join(" ")}>
                      <div className={b.w25}>
                        <small className='blueToGray'><Translate tKey="Website address" /></small>
                      </div>
                      <span className={['firm-box-text', 'firm-box-text-color'].join(' ')}>{domain || '-'}</span>
                    </div>
                    <div className={[b.col12, b.dFlex, b.my1].join(" ")}>
                      <div className={b.w25}>
                        <small className='blueToGray'><Translate tKey="Workhours" /></small>
                      </div>
                      <span className={['firm-box-text', 'firm-box-text-color'].join(' ')}>{workhours || '-'}</span>
                    </div>
                    <div className={[b.col12, b.dFlex, b.my1].join(" ")}>
                      <div className={b.w25}>
                        <small className='blueToGray'><Translate tKey="E-mail address" /></small>
                      </div>
                      <span className={['firm-box-text', 'firm-box-text-color'].join(' ')}>{email || '-'}</span>
                    </div>
                    <div className={[b.col12, b.dFlex, b.my1].join(" ")}>
                      <div className={b.w25}>
                        <small className='blueToGray'><Translate tKey="Application" /></small>
                      </div>
                      <Out className={['btn-success', 'wniosek-btn', b.btn, b.textWhite, b.fontWeightNormal].join(' ')}
                        defaultDescription="Apply for loan" item={mysqlFirm} withIcon={false} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={b.colLg8}>
                <div className={["firm-calc", "blueToWhite", b.mb4].join(" ")}>
                  <div className={`${b.row} product-inputs`}>
                    <div className={b.colLg8}>
                      <ProductInputs settings={productSettings} onChangeInput={setProductsSettings} type="credit" firm/>
                    
                      <div className={b.row}>
                        <div className={[b.colLgAuto, "small-margin"].join(" ")}>
                          <small>
                            <Translate tKey="Borrowed amount" />:

                            <p className={"blue-color"}>
                              {productSettings.input.amount ? <>
                                {productSettings.input.amount} <Translate tKey="cu" />
                              </> : '-'}
                            </p>
                          </small>
                          <small>
                            <Translate tKey="Loan time" />:

                            <p className={"blue-color"}>
                              {productSettings.input.time ? <>
                                {productSettings.input.time} {type_main === 1 ? 'dni' : 'mies'}
                              </> : '-'}
                            </p>
                          </small>
                        </div>
                        <div className={[b.colLgAuto, "small-margin-1"].join(" ")}>
                          <small>
                            <Translate tKey="Interest on the loan" />:

                            <p className={"blue-color"}>
                              {productSettings.costs?.cost ? <>
                                {productSettings.costs.cost} <Translate tKey="cu" />
                              </> : '-'}
                            </p>
                          </small>
                          <small>
                            <Translate tKey="APRC" />:

                            <p className={"blue-color"}>
                              {productSettings.costs?.apr ? <>
                                {productSettings.costs.apr} %
                              </> : '-'}
                            </p>
                          </small>
                          <small>
                            <Translate tKey="Total amount to be repaid" />:

                            <p className={"blue-color"}>
                              {productSettings.costs?.cost && productSettings.input?.amount ? <>
                                {productSettings.costs.cost + productSettings.input.amount} <Translate tKey="cu" />
                              </> : '-'}
                            </p>
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className={[b.colLg4, b.px0].join(" ")}>
                      <div className={[b.justifyContentEnd, b.dFlex, b.colSmAuto,].join(" ")}>
                        <Out className={[b.btn, "btn-success", "btn-cta", b.mt2, b.fontWeightNormal, b.textWhite, b.justifyContentCenter, b.alignItemsCenter, b.dFlex, b.mb2].join(" ")} prefixConfig={config_cta_button} item={mysqlFirm} defaultIcon="Taking-money" defaultDescription="Apply"/>
                      </div>
                      <div className={[b.colLgAuto, b.justifyContentEnd].join(" ")}>
                        <span className={["product-box-comparison-box", "btn-hidden", b.w100, b.mt2,].join(" ")}>
                          <ProductCheckboxComparison item={{ ...mysqlFirm, productType: 'firm' }} settings={product} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="blueToWhite">
                  {d.offer && d.offer.description && (
                    <>
                      <h2 id="section-title-0">
                        {d.offer.header}
                      </h2>
                      <BlogContent content={d.offer.description} className="linone" />
                    </>
                  )}

                  {d.register && d.register.header ? <h2 id="section-title-1">{d.register.header}</h2> : " "}

                  {d.register && d.register.description ? <BlogContent content={d.register.description} className='dark-li' /> : " "}

                  {d.login && d.login.description && (
                    <>
                      <h2 id="section-title-2">{d.login.header}</h2>
                      <BlogContent content={d.login.description} className='dark-li' />
                    </>
                  )}
                  {d.promo && d.promo.description && (
                    <>
                      <h2 id="section-title-3">{d.promo.header}</h2>
                      <BlogContent content={d.promo.description} />
                    </>
                  )}

                  {d.return && d.return.description && (
                    <>
                      <h2 id="section-title-4">{d.return.header}</h2>
                      <BlogContent content={d.return.description} className='dark-li' />
                    </>
                  )}
                  {d.vindication && d.vindication.description && (
                    <>
                      <h2 id="section-title-5">{d.vindication.header}</h2>
                      <BlogContent content={d.vindication.description} className='dark-li' />
                    </>
                  )}
                  {requirements.length > 0 && (
                    <RequirementsList
                      firmType={type}
                      items={requirements}
                      ageMin={age_min}
                      ageMax={age_max}>
                      <h2 id="section-title-6"><Translate tKey="Requirements" /> {name}</h2>
                    </RequirementsList>
                  )
                  }
                  {d.description_advantages && d.description_advantages.description && advantages && (
                    <>
                      <AdvDis advantages={advantages} disadvantages={disadvantages}>
                        <h2 id="section-title-7">{d.description_advantages.header}</h2>
                        <BlogContent content={d.description_advantages.description} />
                      </AdvDis>
                    </>
                  )}

                  {faq?.nodes.length > 0 && (
                    <>
                      <h2 id="section-title-8">
                        {d.faq && d.faq.header ? d.faq.header : ""}
                      </h2>
                      {d.faq && d.faq.description ? <BlogContent content={d.faq.description} className={b.mb3} /> : ""}
                      {faq.nodes.map(({ title, text }, i) => (
                        <Faq title={title} text={text} key={i}/>
                      ))}
                      <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                        <button className={[b.btn, "btn-light", "btn-cta", "button-256",].join(" ")} onClick={() => { toggleForm();toggleFaq(true); }}>
                          <Icon size={20} icon="Question-mark" className={[b.mr1, "icon-blog"].join(" ")} />
                          <Translate tKey="Ask your own question" />
                        </button>
                      </div>
                    </>
                  )}
                  <Comments items={allMysqlComments.nodes} title="standard" descHeader={d.opinions && d.opinions.header ? d.opinions.header : ''} formID={mysqlId} commentType="firm" />
                  <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false);toggleFaq(false); }} formID={mysqlId} commentType="firm" faq={isFaq} />
                </div>
              </div>
            </div>

            {allMysqlFirm.edges.length > 0 && (<>
              <h2><Translate tKey="Similar products" /></h2>
              <SimilarProducts items={allMysqlFirm} product={product} productType='firm' />
            </>)}
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default DFirm;

export const query = graphql`
    query QueryDescriptionFirms($mysqlId: Int!) {

      allMysqlDescriptionFirm(filter: { firm_id: { eq: $mysqlId } }) {
        nodes {
          description,
          header
          name
        }
      }
    
    mysqlFirm: mysqlFirm(mysqlId: { eq: $mysqlId }) {
      name
      url
      age_min
      age_max
      requirements
      address_city
      address_code
      address_no
      address_street
      address_name
      workhours
      domain
      type
      type_main
      phone
      email
      disadvantages
      advantages
      rating
      mysqlId,
      amount_first
      amount_min
      amount_max
      period_min
      period_max
      user_comments
      wniosker
      mysqlImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      process_video
    }
    faq: allMysqlFaq(
      filter: { item: { eq: $mysqlId }, type: { eq: "firm" }, answer: { ne: null } },
      sort: { fields: priority, order: DESC }
    ) {
      nodes {
        title: question
        text: answer
      }
    }

    allMysqlFirm(limit: 4, filter: {mysqlId: {ne: $mysqlId}}) {
      edges {
        node {
          mysqlId
          rating
          rating
          user_comments
          name
          url
          specific_url
          prefix_url
          wniosker
          mysqlImage {
            childImageSharp {
              gatsbyImageData(width: 103)
            }
          }
        }
      }
    }
    allMysqlComments(filter: {type: {eq: "firm"}, item: {eq: $mysqlId}}, sort: {order: DESC, fields: up}) {
      nodes {
        ...CommentData 
      }
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "firm" }}) {
      nodes {
        ...SeoData
      }
    }
  }
`;
